<template>
    <div>
        <v-dialog
            persistent
            v-model="generateDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text justify-space-between">
                    <span>{{ $t('message.eutrEudrDocuments.fillMissingInfo') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-form lazy-validation ref="dataForm" v-model="validForm">
                        <v-layout row pt-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.eutrEudrDocuments.countryOfHarvest') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <v-autocomplete
                                    :items="allCountries"
                                    :rules="[rules.country]"
                                    autocomplete="password"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Country.name"
                                    item-value="Country.id"
                                    solo
                                    v-model="Contract__eutr_country_harvest_id"
                                ></v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.eutrEudrDocuments.regionOfHarvest') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <v-text-field
                                    :value="Contract__eutr_region_harvest"
                                    :rules="[rules.region]"
                                    autocomplete="password"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Contract__eutr_region_harvest = $event"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.eutrEudrDocuments.manufacturingLocation') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <v-text-field
                                    :value="Contract__eutr_mfg_location"
                                    :rules="[rules.mfgLocation]"
                                    autocomplete="password"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Contract__eutr_mfg_location = $event"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pl-7">
                    <v-btn color="default" small @click="generateDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="save()"
                    >{{ $t('message.save') }}</v-btn>
                    <v-btn
                        :loading="loading.generate"
                        color="info"
                        small
                        @click="generatePdf()"
                        v-if="completeInfo == true"
                    >{{ $t('message.generate') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div ref="html2PdfDiv" style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
            <vue-html2pdf
                :show-layout="false"
                :filename="Contract__title + '_EUTR_Due_Diligence_Assessment'"
                :float-layout="true"
                :enable-download="false"
                :html-to-pdf-options="htmlToPdfOptions"
                :paginate-elements-by-height="1120"
                :manual-pagination="true"
                :pdf-quality="2"
                :preview-modal="false"
                pdf-format="a4"
                pdf-content-width="750px"
                ref="html2Pdf"
                @beforeDownload="beforeDownload($event)"
                v-if="validPdf"
            >
                <section slot="pdf-content">
                    <v-app>
                        <div class="d-flex flex-column fill-height paper-a4">
                            <v-col>
                                <v-row no-gutters class="pt-1">
                                    <v-col class="col-2-5">
                                        <img :src="appLogo" class="mb-0 mr-3" width="120px"/>
                                    </v-col>
                                    <v-col class="col-9-5 d-flex flex-column pt-0">
                                        <div>
                                            <span class="header-title">{{ Office__title  }}</span>
                                            <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                                        </div>
                                        <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                        <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(Boolean).join(', ')  }}</span>
                                        <div class="d-flex flex-row">
                                            <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null  && Office__contracttel != ''">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                            <span :class="'header-subtitle pt-1 pb-0' + (Office__contracttel != null && Office__contracttel != '' ? ' ml-10' : '')" v-if="Contract__office_id == 20">{{ getOfficeTaxId() + ' (Head Office)'}}</span>
                                        </div>
                                        <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-8">
                                    <v-col cols="12" class="font-weight-bold text-center">{{ $t('message.eutrEudrDocuments.dueDiligenceAssessment') }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-8">
                                    <v-col cols="4">{{ $t('message.date') }}:</v-col>
                                    <v-col cols="8">{{ assessmentDate }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.contractPo') }}:</v-col>
                                    <v-col cols="8">{{ Contract__title }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.eutrEudrDocuments.tradeName') }}:</v-col>
                                    <v-col cols="8" class="d-flex flex-column">
                                        <span v-for="tradeName in Contract__eutrTradeNames">{{ tradeName }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.eutrEudrDocuments.productType') }}:</v-col>
                                    <v-col cols="8" class="d-flex flex-column">
                                        <span v-for="productType in Contract__eutrProductTypes">{{ productType }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.eutrEudrDocuments.countryOfHarvest') }}:</v-col>
                                    <v-col cols="8">{{ getCountry(Contract__eutr_country_harvest_id) }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.eutrEudrDocuments.regionOfHarvest') }}:</v-col>
                                    <v-col cols="8">{{ Contract__eutr_region_harvest }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.eutrEudrDocuments.manufacturingLocation') }}:</v-col>
                                    <v-col cols="8">{{ Contract__eutr_mfg_location }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-4">
                                    <v-col cols="4">{{ $t('message.certification') }}:</v-col>
                                    <v-col cols="8">{{ Contract__fsc }}</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12">
                                        <p>{{ $t('message.eutrEudrDocuments.confirmedExerciseDueDiligenceShort') }}</p>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12"><span class="font-weight-bold">{{ Office__title }}</span></v-col>
                                </v-row>
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="12" class="d-flex flex-column">
                                        <div class="signature-container">
                                            <img :src="Document__signature" width="120px"/>
                                            <img :src="'/static/img/appic/stamps/stampappsdnbhd.png'" height="80px"/>
                                        </div>
                                        <div class="signature-underline"></div>
                                        <span>{{ Document__signatory }}</span>
                                        <span>{{ Document__signatory_position }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-app>
                </section>
            </vue-html2pdf>
        </div>
    </div>
</template>

<script>
import {api} from "Api";
import {log} from "Helpers/helpers"
import VueHtml2pdf from 'vue-html2pdf';
import {months} from '../../../store/modules/appic/constants'
import AppConfig from "Constants/AppConfig";
import {mapActions, mapGetters} from "vuex";
import S3 from 'aws-s3-pro';

export default {
    name: "DueDiligenceAssessment",
    props: ['contractId','dialog'],
    components: {VueHtml2pdf},
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            Contract__title: null,
            Contract__productorigin_id: 1,
            Contract__eutr_country_harvest_id: 1,
            Contract__eutr_region_harvest: null,
            Contract__eutr_mfg_location: null,
            Contract__fsc: null,
            Contract__office_id: 1,
            Contract__eutrTradeNames: [],
            Contract__eutrProductTypes: [],
            Contract__eutrCertifications: [],
            Document__signatory: null,
            Document__signatory_position: null,
            Document__signature: null,
            months: months,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__contracttel: null,
            Office__companyno: null,
            Office__country: null,
            Office__currency_id: 1,
            Office__contractemail: null,
            Office__gstno: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: null,
            Office__stamp_ws: null,
            Office__taxno_type: null,
            Office__website: null,
            completeInfo: false,
            generate_dialog: false,
            validPdf: false,
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.95
                },
                html2canvas: {
                    scale: 1.5
                }
            },
            loading: {
                get: false,
                generate: false,
                save: false
            },
            rules: {
                country: v => !!v || this.$t('message.required'),
                region: v => !!v || this.$t('message.required'),
                mfgLocation: v => !!v || this.$t('message.required'),
            },
            validForm: false
        }
    },
    computed: {
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('user', {
            s3Keys: 's3Keys'
        }),
        assessmentDate() {
            const date = new Date()
            const month = this.months.find(m => m.Month.id == date.getMonth() + 1)
            return month.Month.text + ' ' + date.getDate().toString() + ', ' + date.getFullYear().toString()
        },
        baseURL(){
            return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
        },
        config() {
            return {
                bucketName: 'apptimber-appic',
                dirName: 'others',
                region: 'ap-southeast-1',
                accessKeyId: this.s3Keys.id,
                secretAccessKey: this.s3Keys.secret,
                s3Url: this.baseURL
            }
        },
        generateDialog: {
            get() {
                return this.generate_dialog;
            },
            set(value){
                this.generate_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        S3Client(){
            return new S3(this.config);
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                this.loadEutrDocumentById(this.contractId)
                    .then(() => {
                        this.loading.get = false
                        if(this.Contract__eutr_country_harvest_id && this.Contract__eutr_region_harvest && this.Contract__eutr_mfg_location){
                            this.completeInfo = true
                        } else {
                            this.completeInfo = false
                        }
                        if(this.Contract__eutr_country_harvest_id == null){
                            this.Contract__eutr_country_harvest_id = this.Contract__productorigin_id
                        }
                    })
                    .catch((error) => {
                        this.loading.get = false
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            } else {
                this.Contract__title = null
                this.Contract__eutr_country_harvest_id = 1
                this.Contract__eutr_region_harvest = null
                this.Contract__eutr_mfg_location = null
                this.Document__signatory = null
                this.Document__signatory_position = null
                this.Document__signature = null
                this.Office__title = null
                this.Office__address1 = null
                this.Office__address2 = null
                this.Office__city = null
                this.Office__contracttel = null
                this.Office__companyno = null
                this.Office__country = null
                this.Office__currency_id = 1
                this.Office__contractemail = null
                this.Office__gstno = null
                this.Office__postcode = null
                this.Office__state = null
                this.Office__stamp = null
                this.Office__stamp_ws = null
                this.Office__taxno_type = null
                this.Office__website = null
                this.completeInfo = false
                this.validPdf = false
            }
            this.generate_dialog = value
        }
    },
    methods: {
        ...mapActions('country',{
            getAllCountries: 'getAllCountries'
        }),
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                let reader = new FileReader()
                let _this = this
                reader.readAsDataURL(pdf.output('blob'));
                reader.onloadend = function () {
                    const fileName = _this.Contract__title.replace(' ','_') + '_EUTR_Due_Diligence_Assessment'
                    const uploadObject = new File([pdf.output('blob')], fileName + '.pdf')
                    _this.S3Client
                        .uploadFile(uploadObject, fileName)
                        .then((response) => {
                            if (response.status == 204) {
                                // create asset document in DB
                                let document = {
                                    id: null,
                                    asset: 'shipping',
                                    asset_id: parseInt(_this.contractId),
                                    asset_title: 'EUTR Due Diligence Assessment',
                                    doctype_id: 413,
                                    location: response.location,
                                    uploaded: Date.now(),
                                    customeraccess: 1,
                                    asset_type: 'pdf'
                                }
                                const promise = new Promise((resolve, reject) => {
                                    api
                                        .post('/documents', {
                                            document: document
                                        })
                                        .then((response) => {
                                            if (response.data.status == 'success') {
                                                resolve(response.data.data)
                                            } else {
                                                reject(response.data.status)
                                            }
                                        })
                                        .catch((err) => {
                                            reject(err)
                                        })
                                })

                                promise
                                    .then((newDocument) => {
                                        document['id'] = newDocument?.id
                                        // document['uploaded'] = newDocument?.uploaded
                                        const fileObject = {
                                            AssetDocument: document
                                        }
                                        _this.$emit('pdf-uploaded', fileObject)
                                        _this.loading.generate = false
                                        _this.generateDialog = false
                                    })
                                    .catch((e) => {
                                        _this.$toast.error(e,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.$emit('pdf-not-uploaded')
                                        _this.loading.generate = false
                                    })
                            }
                        })
                        .catch((e) => {
                            _this.$toast.error(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            _this.$emit('pdf-not-uploaded')
                            _this.loading.generate = false
                        })
                }
            })
        },
        getCountry(countryId) {
            const country = this.allCountries.find(c => c.Country.id == countryId)
            if(country){
                return country.Country.name
            }
            return null
        },
        generatePdf() {
            // log('generating PDF')
            this.validPdf = true
            this.loading.generate = true
            const sleepUntil = async (f, timeoutMs) => {
                return new Promise((resolve, reject) => {
                    const timeWas = new Date();
                    const wait = setInterval(function() {
                        if (f()) {
                            clearInterval(wait);
                            resolve();
                        } else if (new Date() - timeWas > timeoutMs) { // Timeout
                            clearInterval(wait);
                            reject();
                        }
                    }, 20);
                });
            }
            try {
                sleepUntil(() => this.$refs.html2Pdf, 5000)
                    .then(() => {
                        this.$refs.html2Pdf.generatePdf()
                    })
            } catch {

            }
        },
        getOfficeTaxId () {
            let taxId = ''
            if(this.Office__gstno != null && this.Office__taxno_type != null) taxId = this.Office__taxno_type + ': ' + this.Office__gstno
            if(this.Office__gstno != null && this.Office__taxno_type == null) taxId = this.Office__gstno
            return taxId
        },
        loadEutrDocumentById(contractId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/eutr-eudr/" + contractId + "/dda")
                    .then(response => {
                        for(let key in response.data.data[0]){
                            this[key] = response.data.data[0][key]
                        }
                        resolve(contractId)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        save() {
            const valid = this.$refs.dataForm.validate()
            if(valid) {
                this.loading.save = true
                const contract = {
                    eutr_country_harvest_id: this.Contract__eutr_country_harvest_id,
                    eutr_region_harvest: this.Contract__eutr_region_harvest,
                    eutr_mfg_location: this.Contract__eutr_mfg_location
                }
                api
                    .put('/eutr-eudr/' + this.contractId,{
                        contract: contract
                    })
                    .then((response)=>{
                        if (response.data.status == 'success') {
                            this.completeInfo = true
                        } else {
                            this.$toast.error(response.data.status,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.save = false
                    })
                    .catch((e)=>{
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        }
    },
    created() {
        if(this.allCountries.length == 0) this.getAllCountries()
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.document-caption {
    font-size: 16px !important;
    line-height: 18px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 20pt !important;
    line-height: 25px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10.5pt !important;
}
.signature-underline {
    width: 150px;
    height: 1px;
    background-color: black;
}
.paper-a4 {
    height: 1065px;
    margin-left: 75px;
    margin-top: 35px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:10pt !important;
    font-size:10pt !important;
    padding:2px !important;
    background-color: #FFFFFF !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
* {
    text-rendering: geometricprecision !important;
}
.signature-container {
    position: relative;
    width: 100%;
    height: 90px;
}
.signature-container > img {
    position: absolute;
    top:0;
    left:0;
}
.signature-container > img:first-child {
    z-index: 2;
}
.signature-container > img:last-child {
    left: 20px !important;
}
</style>